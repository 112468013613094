import { Injectable } from '@angular/core';
import {
  BaselineRequest,
  BaselineResponse,
  DARequest,
  DAResponse,
  GateRules,
  GroupedOffers,
  GroupedOffersDataModel,
  OfferRequest,
  OfferResponse,
  ScheduleRequest,
  ScheduleResponse
} from '@dr-customer-offers-ui/lib-interfaces';
import { ApiTime, Timezone } from 'common-utils/dist/models/time';
import * as parser from 'cron-parser';
import * as moment from 'moment';
import { DataModel, DetailTableCellModel, NgxIntervalDataGridRowModel, Week } from 'ngx-interval-data-grid';
import { GroupedData, RegConfig } from '../models';

@Injectable({ providedIn: 'root' })
export class DataModelService {
  getGridDataFromOffers(offers: OfferResponse[], regConfig: RegConfig): DataModel[] {
    const registrationTimezone = new Timezone(regConfig.timeZone);
    return offers.map((offer: OfferResponse) => {
      const offerStartTimeUtc = new ApiTime(moment.utc(offer.offer_start_dttm_utc).format(ApiTime.momentFormat));
      const offersData: DataModel = {
          product_id: offer.product_id,
          program_id: offer.program_id,
          registration_id: offer.registration_id,
          offer_start_dttm_utc: offer.offer_start_dttm_utc,
          offer_end_dttm_utc: offer.offer_end_dttm_utc,
          offer_value: offer.offer_value,
          offer_opt_out_state: offer.offer_opt_out_state,
          update_dttm: offer.updated_dttm,
          updated_by: offer.updated_by,
          cleared: offer.cleared,
          zero_reason: offer.zero_reason?.[0] ?? null,
          gate_closed: offer.gate_closed,
          is_emergency: this.isEmergency(regConfig.gateRules, offerStartTimeUtc, registrationTimezone),
          is_dst: offer.dst_editable,
          is_holiday: offer.is_holiday
        };
    return offersData;
    })
  }

  getGridDataFromDA(offers: DAResponse[], regConfig: RegConfig, startDate: string, endDate: string): DataModel[] {
    const blankOffers: DataModel[] = this.getBlankIntervals(regConfig, startDate, endDate);
    blankOffers.map((row: DataModel) => {
      offers.map((offerRow: DAResponse) => {
        if (
          row.offer_start_dttm_utc === offerRow.da_start_dttm_utc &&
          row.offer_end_dttm_utc === offerRow.da_end_dttm_utc &&
          offerRow.declared_availability !== null
        ) {
          row.offer_value.kW = offerRow.declared_availability;
          row.zero_reason = null;
        }
      });
    });
    return blankOffers;
  }

  private getBlankIntervals(regConfig: RegConfig, startDate: string, endDate: string): DataModel[] {
    const offersData: DataModel[] = [];
    const registrationTimezone = new Timezone(regConfig.timeZone);
    for (let m = moment(startDate); m.isBefore(endDate); m.add(regConfig.intervalFrequency, 'minutes')) {
      const intervalStartTimeUtc = m.clone();
      const offerStartTimeUtc = new ApiTime(intervalStartTimeUtc.format(ApiTime.momentFormat));
      const intervalEndTimeUtc = m.clone().add(regConfig.intervalFrequency, 'minutes');
      const dataModel: DataModel = {
        product_id: regConfig.productId,
        program_id: regConfig.programId,
        registration_id: regConfig.registrationId,
        offer_start_dttm_utc: intervalStartTimeUtc.toISOString(),
        offer_end_dttm_utc: intervalEndTimeUtc.toISOString(),
        offer_value: { kW: null },
        offer_opt_out_state: false,
        update_dttm: null,
        updated_by: null,
        cleared: null,
        zero_reason: null,
        gate_closed: this.isGateClosed(regConfig.gateRules, offerStartTimeUtc, registrationTimezone),
        is_emergency: this.isEmergency(regConfig.gateRules, offerStartTimeUtc, registrationTimezone),
        is_dst: false,
        is_holiday: false
      };
      offersData.push(dataModel);
    }
    return offersData;
  }

  private getBlankSchedule(regConfig: RegConfig): DataModel[] {
    const offersData: DataModel[] = [];
    // TODO
    // Current week to be sent to API for creating schedule.
    // In future, consider sending fixed dates (e.g. Jan 1st - Jan 7th)
    const startDate = moment().tz(regConfig.timeZone).startOf('week');
    const endDate = moment().tz(regConfig.timeZone).endOf('week');
    let interval_idx = 0;
    for (let m = moment(startDate); m.isBefore(endDate); m.add(regConfig.intervalFrequency, 'minutes')) {
      const intervalStartTimeUtc = m.clone();
      const intervalEndTimeUtc = m.clone().add(regConfig.intervalFrequency, 'minutes');
      const dataModel: DataModel = {
        product_id: regConfig.productId,
        program_id: regConfig.programId,
        registration_id: regConfig.registrationId,
        offer_start_dttm_utc: intervalStartTimeUtc.toISOString(),
        offer_end_dttm_utc: intervalEndTimeUtc.toISOString(),
        offer_value: { kW: null },
        offer_opt_out_state: false,
        interval_idx: interval_idx,
        update_dttm: null,
        updated_by: null,
        cleared: null,
        zero_reason: null,
        gate_closed: false,
        is_emergency: false,
        is_dst: false,
        is_holiday: false
      };
      interval_idx++;
      offersData.push(dataModel);
    }
    return offersData;
  }

  // private getBlankBaselines(regConfig: RegConfig, startDate: string, endDate: string): DataModel[] {
  //   const offersData: DataModel[] = [];
  //   const registrationTimezone = new Timezone(regConfig.timeZone);
  //   for (let m = moment(startDate); m.isBefore(endDate); m.add(regConfig.intervalFrequency, 'minutes')) {
  //     const intervalStartTimeUtc = m.clone();
  //     const intervalEndTimeUtc = m.clone().add(regConfig.intervalFrequency, 'minutes');
  //     const offerStartTimeUtc = new ApiTime(intervalStartTimeUtc.format(ApiTime.momentFormat));
  //     const dataModel: DataModel = {
  //       product_id: regConfig.productId,
  //       program_id: regConfig.programId,
  //       registration_id: regConfig.registrationId,
  //       offer_start_dttm_utc: intervalStartTimeUtc.toISOString(),
  //       offer_end_dttm_utc: intervalEndTimeUtc.toISOString(),
  //       offer_value: { kW: null },
  //       offer_opt_out_state: false,
  //       update_dttm: null,
  //       updated_by: null,
  //       cleared: null,
  //       zero_reason: null,
  //       gate_closed: this.isGateClosed(regConfig.gateRules, offerStartTimeUtc, registrationTimezone),
  //       is_emergency: false,
  //       is_dst: false,
  //       is_holiday: false
  //     };
  //     offersData.push(dataModel);
  //   }
  //   return offersData;
  // }

  private isEmergency(gateRules: GateRules, offerStartDttmUtc: ApiTime, registrationTimezone: Timezone): boolean {
    // Check if post_gate_edits is null or allow_post_gate_closure_edits is false immediately
    if (!gateRules.post_gate_edits || !gateRules.post_gate_edits.allow_post_gate_closure_edits) return false;

    // Check if the gate is closed for the given parameters
    if (!this.isGateClosed(gateRules, offerStartDttmUtc, registrationTimezone)) return false;

    // Compare the start date with the current time to determine emergency
    const startDate = moment(offerStartDttmUtc.time);
    const currentTime = moment();
    return startDate.isAfter(currentTime);
  }

  private isGateClosed(gateRules: GateRules, offerStartDttmUtc: ApiTime, registrationTimezone: Timezone): boolean {
    if (gateRules.gate_closes_every === null) {
      // From the current config, this indicates it is a Rolling Window
      const gateClosureMinutesToAdd: number = Number(gateRules.gate_closes_from_relative) + Number(gateRules.gate_closure_tolerance);
      const gateClosureTimestamp = moment().add(gateClosureMinutesToAdd, 'minutes').valueOf();
      return offerStartDttmUtc.time < gateClosureTimestamp ? true : false;
    } else if (gateRules.gate_closes_every !== null) {
      // This indicates that gate closure is not a Rolling window.
      // TODO: This replacing of 0-4 to 1-5 is because API Cron library does not follow IEEE standards of considering 0 as Sunday and 1 as Monday. This will need to be looked into in future.
      const cronExpression = gateRules.gate_closes_every.includes('0-4')
        ? gateRules.gate_closes_every.replace('0-4', '1-5')
        : gateRules.gate_closes_every;
      const currentTimeInProgramTz = moment().tz(registrationTimezone.timezone);
      const options = {
        tz: registrationTimezone.timezone
      };
      const interval = parser.parseExpression(cronExpression, options);
      const cutOffTime = moment(interval.next().toDate()).tz(registrationTimezone.timezone).endOf('day');
      const cutOffTimeInMinutes = Number(moment(interval.next().toDate()).tz(registrationTimezone.timezone).format('HH'));
      if (Number(currentTimeInProgramTz.format('HH')) < cutOffTimeInMinutes) {
        if (moment(offerStartDttmUtc.fullDate).tz(registrationTimezone.timezone).isAfter(currentTimeInProgramTz.endOf('day'))) {
          return false;
        } else {
          return true;
        }
      } else {
        if (moment(offerStartDttmUtc.fullDate).tz(registrationTimezone.timezone).isAfter(cutOffTime)) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  getGridDataFromSchedule(schedule: ScheduleResponse[], regConfig: RegConfig): DataModel[] {
    const scheduleData: DataModel[] = [];
    if (!schedule.length) return this.getBlankSchedule(regConfig);
    schedule.forEach((schedule) => {
      const dataModel: DataModel = {
        product_id: schedule.product_id,
        program_id: schedule.program_id,
        registration_id: schedule.registration_id,
        offer_start_dttm_utc: schedule.offer_start_dttm_utc,
        offer_end_dttm_utc: schedule.offer_end_dttm_utc,
        offer_value: schedule.offer_value,
        offer_opt_out_state: schedule.offer_opt_out_state,
        update_dttm: schedule.updated_dttm,
        updated_by: schedule.updated_by,
        interval_idx: schedule.interval_idx,
        cleared: null,
        zero_reason: null,
        gate_closed: false,
        is_emergency: false,
        is_dst: false,
        is_holiday: false
      };
      scheduleData.push(dataModel);
    });
    return scheduleData;
  }

  getOffersToPost(dataCells: DetailTableCellModel[], regConfig: RegConfig | null | undefined, changeReason = ''): OfferRequest[] {
    if (!regConfig) return [];
    const offersToPost: OfferRequest[] = [];
    dataCells.forEach((cell) => {
      const offer: OfferRequest = {
        registration_id: regConfig.registrationId,
        program_id: regConfig.programId,
        product_id: regConfig.productId,
        offer_start_dttm_utc: moment(cell.start_date_time).tz(regConfig.timeZone).toISOString(),
        offer_end_dttm_utc: moment(cell.end_date_time).tz(regConfig.timeZone).toISOString(),
        offer_value: { kW: cell.value ?? 0 },
        offer_opt_out_state: cell.opt_out,
        is_emergency: cell.is_emergency
      };

      if(regConfig.gateRules.post_gate_edits.provide_change_reason && changeReason){
        offer.post_gate_edit_reason = changeReason;
      }

      offersToPost.push(offer);
    });
    return offersToPost;
  }

  getDAOffersToPost(dataCells: DetailTableCellModel[], regConfig: RegConfig | null | undefined, changeReason = ''): DARequest[] {
    if (!regConfig) return [];
    const daOffersToPost: DARequest[] = [];
    dataCells.forEach((cell) => {
      const daOffer: DARequest = {
        registration_id: regConfig.registrationId,
        program_id: regConfig.programId,
        product_id: regConfig.productId,
        da_start_dttm_utc: moment(cell.start_date_time).tz(regConfig.timeZone).toISOString(),
        da_end_dttm_utc: moment(cell.end_date_time).tz(regConfig.timeZone).toISOString(),
        declared_availability: cell.value ?? 0,
        reason_code: changeReason
      };

      if(regConfig.gateRules.post_gate_edits.provide_change_reason && changeReason){
        daOffer.reason_code = changeReason;
      }

      daOffersToPost.push(daOffer);
    });
    return daOffersToPost;
  }

  getScheduleToPost(dataCells: DetailTableCellModel[], groupedData: GroupedData | null): ScheduleRequest[] {
    if (!groupedData || !groupedData.values || !groupedData.regConfig) return [];

    const { registrationId, programId, productId, timeZone } = groupedData.regConfig;

    if (!registrationId || !programId || !timeZone) return [];

    // Defining daysOfWeek to help iterate over
    const daysOfWeek: Week[] = [Week.SUNDAY, Week.MONDAY, Week.TUESDAY, Week.WEDNESDAY, Week.THURSDAY, Week.FRIDAY, Week.SATURDAY];

    // Explicitly type the accumulator as DetailTableCellModel[]
    const scheduleFromCells = groupedData.values.reduce<DetailTableCellModel[]>((acc: DetailTableCellModel[], val: NgxIntervalDataGridRowModel) => {
      daysOfWeek.forEach((day: Week) => {
        const cell: DetailTableCellModel = val[day];
        if (cell) acc.push(cell);
      });
      return acc;
    }, []);

    // Sorting the cells by interval_idx, usage of 0 is just a fallback for interval_idx being undefined
    scheduleFromCells.sort((a: DetailTableCellModel, b: DetailTableCellModel) => (a.interval_idx || 0) - (b.interval_idx || 0));

    const dataCellsMap = new Map(dataCells.map((cell: DetailTableCellModel) => [cell.interval_idx, cell]));

    // Update scheduleFromCells with values and opt_out status from dataCells where interval_idx matches
    const mergedSchedule: DetailTableCellModel[] = scheduleFromCells.map((scheduleCell: DetailTableCellModel) => {
      const changedCell: DetailTableCellModel | undefined = dataCellsMap.get(scheduleCell.interval_idx);
      return changedCell
        ? {
            ...scheduleCell,
            value: changedCell.value,
            opt_out: changedCell.opt_out
          }
        : scheduleCell;
    });

    // Transforming the merged schedule into an array of ScheduleRequest objects
    return mergedSchedule.map((cell: DetailTableCellModel) => ({
      registration_id: registrationId,
      program_id: programId,
      product_id: productId ?? programId,
      offer_start_dttm_utc: moment(cell.start_date_time).tz(timeZone).toISOString(),
      offer_end_dttm_utc: moment(cell.end_date_time).tz(timeZone).toISOString(),
      offer_value: { kW: cell.value ?? 0 },
      offer_opt_out_state: cell.opt_out,
      interval_idx: cell.interval_idx ?? 0
    }));
  }

  getBaselineToPost(dataCells: DetailTableCellModel[], groupedData: GroupedData | null): BaselineRequest[] {
    if (!groupedData || !groupedData.values || !groupedData.regConfig) return [];

    const { registrationId, programId, productId, timeZone } = groupedData.regConfig;

    if (!registrationId || !programId || !productId || !timeZone) return [];

    // Transforming the merged schedule into an array of ScheduleRequest objects
    return dataCells.map((cell: DetailTableCellModel) => ({
      registration_id: registrationId,
      program_id: programId,
      product_id: productId,
      baseline_start_dttm_utc: moment(cell.start_date_time).tz(timeZone).toISOString(),
      baseline_end_dttm_utc: moment(cell.end_date_time).tz(timeZone).toISOString(),
      baseline_value: cell.value ?? 0
    }));
  }

  getGridDataFromBaseline(baselines: BaselineResponse[], regConfig: RegConfig): DataModel[] {
    const registrationTimezone = new Timezone(regConfig.timeZone);
    return baselines.map((baseline: BaselineResponse) => {
      const baselineStartTimeUtc = new ApiTime(moment.utc(baseline.baseline_start_dttm_utc).format(ApiTime.momentFormat));
      return {
        product_id: baseline.product_id,
        program_id: baseline.program_id,
        registration_id: baseline.registration_id,
        offer_start_dttm_utc: baseline.baseline_start_dttm_utc,
        offer_end_dttm_utc: baseline.baseline_end_dttm_utc,
        offer_value: { 'kW': baseline.baseline_value },
        offer_opt_out_state: false,
        update_dttm: baseline.updated_dttm,
        updated_by: baseline.updated_by,
        cleared: null,
        zero_reason: null,
        gate_closed: this.isGateClosed(regConfig.gateRules, baselineStartTimeUtc, registrationTimezone),
        is_emergency: false,
        is_dst: baseline.dst_editable,
        is_holiday: false
      };
    })
  }

  getGroupedOffersToPUT(detailCells: DetailTableCellModel[]): GroupedOffersDataModel[] {
    const groupedOffersMap: { [key: string]: GroupedOffers[] } = {};

    detailCells.forEach((cell: DetailTableCellModel) => {
      const groupedOffer: GroupedOffers = {
        group_name: cell.timePeriodKey,
        offer_value: { kW: cell.value ?? 0 },
        offer_opt_out_state: cell.opt_out,
        updated_by: ''
      };

      if (!groupedOffersMap[cell.start_date_time]) {
        groupedOffersMap[cell.start_date_time] = [];
      }
      groupedOffersMap[cell.start_date_time].push(groupedOffer);
    });

    const groupedOffersDataModels: GroupedOffersDataModel[] = Object.keys(groupedOffersMap).map(date => ({
      date,
      groups: groupedOffersMap[date]
    }));

    return groupedOffersDataModels;
  }

}
