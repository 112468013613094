import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DARequest, DAResponse } from '@dr-customer-offers-ui/lib-interfaces';
import { APIData } from 'common-utils/dist/models/api';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiEndpointsService } from '../api-endpoint/api-endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class DeclaredAvailabilityService {

  constructor(
    private http: HttpClient,
    private apiService: ApiEndpointsService,
  ) { }

  getDA(registration_id: string, start_dttm: string, end_dttm: string): Observable<DAResponse[] | null> {
    const { baseUrl } = this.apiService;
    const url = `${baseUrl}/registrations/` + registration_id + `/declared-availabilities?start_dttm=` + start_dttm + `&end_dttm=` + end_dttm + `&full_response=true`;
    return this.http.get<APIData<DAResponse[] | null>>(url, {withCredentials: true}).pipe(
      map((res) => this.extractOffersData(res)),
      catchError(this.handleError),
    );
  }

  putDAOffers(registration_id: string, offers: DARequest[]): Observable<DARequest[] | null> {
    const { baseUrl } = this.apiService;
    const url = `${baseUrl}/registrations/` + registration_id + `/declared-availabilities`;
    return this.http.put<APIData<DARequest[] | null>>(url, offers, {withCredentials: true}).pipe(
        map((res) => this.extractOffersData(res))
      );
  }

  private extractOffersData(res: APIData<DAResponse[] | null>): DAResponse[] | null {
    const payload = res.data;
    return payload as DAResponse[] | null;
  }

  private handleError(error: Error | HttpErrorResponse) {
    // Log the error if needed
    console.error('Error:', error);
    // Emit null to indicate failure
    return of(null);
  }
}

